import React, { Component, useEffect, useState } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {APIHOST,updateloginStatus,setMerchant,setToken,isLoggedIn,isOutletSet, loadSuccess, loadError, merchantKey} from '../../../utils/gaia';
import ToolBar from '../../Components/toolbarNL'
import Buttontb from '../../Components/Button'


import { Button, Form, FormGroup, Label, Input, FormText,Spinner } from 'reactstrap';
import { Images } from '../../../utils/Images';
 
const Login = (props) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    if(isLoggedIn()){

      navigate(`/app`)
    }
  },[])

  const handleSubmit = (evt) =>{
    evt.preventDefault();
    if(username === "" || password === ""){
      loadError("Please enter username and password")
      return;
    }
    setLoading(true)
    Axios.post(`${APIHOST}customer_login`, {
      phone: username,
      password: password,
      mct: merchantKey,
      type: 1
    }).then(function(res){
        let {data} = res;
        updateloginStatus(true);
        setToken(data.access_token);
        setMerchant(data.details);
        setLoading(false)
        loadSuccess("Welcome Back")
        setTimeout(()=>{  window.location.replace(`/app`)},1500)
    }).catch(function(er){
      setLoading(false)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }

    return (
      <>
      <div className="container bg">
        <div id="login">
        <div className="inner">
          <div className="top">
            <img className="login-logo" src={Images.logo}/>
            <h1>Log in to your account</h1>
            <div className="frm-box">
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="username">Email Address / Phone Number</Label>
                  <Input className="text-field" type="text" id="username" name="username" placeholder='Enter your Email Address / Phone Number' onChange={event => setUsername(event.target.value)} required />
                </FormGroup>
                <FormGroup  className="mb-4">
                  <Label for="username">PASSWORD</Label>
                  <Input className="text-field" type="password" id="password" name="password" placeholder='Enter your password' onChange={event => setPassword(event.target.value)} required />
                </FormGroup>
              <Buttontb loader={loading} text={"LOG IN"} classList={"tmpl-main-btn filled"} />
              </Form>
              <p className='form-links'>
                <span><a href="/forgot">Forgot your Password?</a></span>|<span><a href="/register">Haven't registered yet?</a></span>
              </p>
            </div>
          </div>
          <div className="btm">
           
          </div>
        </div>
      </div>
    </div>
    </>
    );
}

export default Login;
