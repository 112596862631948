import React, { Component, useEffect, useState } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {APIHOST,updateloginStatus,setMerchant,setToken,isLoggedIn,isOutletSet, loadError, loadSuccess, merchantId} from '../../../utils/gaia';
import ToolBar from '../../Components/toolbarNL'
import Buttontb from '../../Components/Button'


import { Button, Form, FormGroup, Label, Input, FormText,Spinner } from 'reactstrap';

let countdown = null;
 
const Forgot = (props) => {
  const navigate = useNavigate();
  const [blob, setUsername] = useState("")
  const [otp, setOtp] = useState("")
  const [slug, setSlug] = useState("")
  const [timer, setTimer] = useState(300);
  const [display, setDisplay] = useState("00:00")
  const [complete, setComplete] = useState(false)
  const [resend, setResend] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    if(isLoggedIn()){
      navigate(`/app`)
    }
  },[])

  const handleVerify = (evt) =>{
    evt.preventDefault();
    if(otp === ""){
      loadError("Please enter your OTP")
      return;
    }
    setLoading(true)
    Axios.post(`${APIHOST}forgotv1`, {
      otp : otp,
      key : slug,
      action : "confirm"
    }).then(function(res){
        let {data} = res;
        setLoading(false);
        loadSuccess(data.msg)
        navigate("/new-password",{state : {key : slug, mobile : blob}})
    }).catch(function(er){
      setLoading(false)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }

  const handleSubmit = (evt) =>{
    evt.preventDefault();
    if(blob === ""){
      loadError("Please enter mobile number")
      return;
    }
    setLoading(true)
    Axios.post(`${APIHOST}forgotv1`, {
      phone : blob,
      peer : merchantId,
      action : "init"
    }).then(function(res){
        let {data} = res;
        setLoading(false);
        loadSuccess(data.msg)
        setComplete(true)
        setSlug(data.slug)
        let cdn = 300
        var minutes, seconds;

        countdown = setInterval(function () {
            minutes = parseInt(cdn / 60, 10);
            seconds = parseInt(cdn % 60, 10);
    
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
    
            setDisplay(`${minutes} minutes  ${seconds} seconds`);
    
            if (cdn < 0) {
              clearInterval(countdown)
              setResend(true)
              setTimer(300)
            }else{
              cdn--
              setTimer(cdn)
            }
        }, 1000);
        
    }).catch(function(er){
      setLoading(false)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }

    return (
      <>
      <div className="container bg">
      <ToolBar title="login" past="/" hasMenu={false} />
      
        <div id="login">
        <button onClick={()=> navigate("/")} className="btn back-btn" ><i className="fa fa-chevron-left"></i></button>
        <div className="inner">
          <div className="top">
            <h1>Forgot Password</h1>
            <div className="frm-box">
            {!complete ?
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="username">Email Address / Phone Number</Label>
                  <Input autoComplete="off" className="text-field" type="text" id="blob"  name="blob" placeholder='Enter your Email Address/Phone Number' onChange={event => setUsername(event.target.value)} required />
                </FormGroup>
                <Buttontb loader={loading} text={"confirm"} classList={"tmpl-main-btn filled"} />
                <p className='otp-timer' style={{marginTop : "30px", textAlign : "center", fontSize : "15px" }}>An OTP will be sent to the registered <strong style={{    textDecoration: "underline"}}>EMAIL ADDRESS</strong> associated with this handphone number.<br /><br />Please check your spam/junk mail in case you do not receive in your inbox.</p>
              </Form>:
              <Form autoComplete="off" onSubmit={handleVerify}>
                <FormGroup autoComplete="off">
                  <Label for="username">Enter your OTP</Label>
                  <Input value={otp} autoComplete='new-password' className="text-field" type="number" id="otp"  name="otp" placeholder='Enter your OTP' onChange={event => setOtp(event.target.value)} required />
                </FormGroup>
                <Buttontb loader={loading} text={"confirm"} classList={"tmpl-main-btn filled"} />
                <p className='form-links'>
                  {resend ? <p className='otp-timer'>Your OTP will expire in <strong>{display}</strong></p> :
                  <a onClick={handleSubmit}>Resend OTP</a>}
                </p>
                <p className='otp-timer' style={{marginTop : "30px", textAlign : "center", fontSize : "15px" }}>An OTP will be sent to the registered <strong style={{    textDecoration: "underline"}}>EMAIL ADDRESS</strong> associated with this handphone number.<br /><br />Please check your spam/junk mail in case you do not receive in your inbox.</p>
               </Form>}
            </div>
          </div>
          <div className="btm">
           
          </div>
        </div>
      </div>
    </div>
    </>
    );
}

export default Forgot;
